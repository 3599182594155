import { Link, graphql } from "gatsby"
import React from "react"
import "../styles/main.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/seo"
import VenueBanner from "../components/VenueBanner/VenueBanner"
import loadable from "@loadable/component"
import FloatingCTAs from "../components/FloatingCTAs/FloatingCTAs"
import { enquireURL } from "../common/utils/urls"
import useCompanyInfo from "../hooks/useCompanyInfo"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"

const VenueGallery = loadable(() => import("../components/Gallery/Gallery"))
const VenueDetails = loadable(() =>
  import("../components/VenueDetails/VenueDetails")
)
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const OtherVenuesSlider = loadable(() =>
  import("../components/OtherVenuesSlider/OtherVenuesSlider")
)

const VenueDetailPage = ({ data }) => {
  const venue = data.strapiVenue

  const { phone } = useCompanyInfo()

  if (!venue) return null

  const { modules, name, strapi_id, images, imagetransforms } = venue

  return (
    <motion.div
      className="venue-detail-page"
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <SEO
        title={`${venue.name} | Venue management`}
        description={`Explore about ${venue.name} here. Contact Blank Canvas for efficiently manage venues for successful events with expert coordination`}
        image={venue.tile_image?.url}
      />
      <VenueBanner venue={venue} />
      <VenueGallery
        name={name}
        strapi_id={strapi_id}
        images={images}
        imagetransforms={imagetransforms}
        imagename="venue.images.gallery_image"
      />
      <VenueDetails venue={venue} />
      {modules?.map((module, index) => (
        <TileBlock
          key={"module" + index}
          imagetransforms={imagetransforms}
          strapi_id={strapi_id}
          module={module}
          venueDetailPage
        />
      ))}
      <OtherVenuesSlider venueID={venue.id} />
      <FloatingCTAs>
        <Link to={enquireURL} className="button">
          <i className="icon icon-mail"></i>
          <span>enquire</span>
        </Link>
        <Link to={`tel:${phone}`} className="button">
          <i className="icon icon-phone"></i>
          <span>call us</span>
        </Link>
      </FloatingCTAs>
    </motion.div>
  )
}

export default VenueDetailPage

export const query = graphql`
  query ($venue_id: Int) {
    strapiVenue(strapi_id: { eq: $venue_id }) {
      ...venueFragment
    }
  }
`
