import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./VenueBanner.scss"
import { parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import ImageModule from "../../modules/ImageModule"
import { enquireURL } from "../../common/utils/urls"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const VenueBanner = ({ venue }) => {
  const { isTablet, isMobile } = useDeviceMedia()

  const { phone } = useCompanyInfo()

  const { name, description, bg_image, imagetransforms, strapi_id } = venue

  let processedImages = imagetransforms?.bg_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div className="venue-banner-wrap">
      {!isTablet && (
        <motion.div
          className="venue-banner-img"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
              transition: {
                duration: 1.2,
                ease: "easeOut",
              },
            },
          }}
        >
          {/* <img src={bg_image.url} alt="venue-banner-img" /> */}
          <ImageModule
            ImageSrc={bg_image}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"venue.bg_image.image"}
            strapi_id={strapi_id}
          />
          <div className="banner-overlay"></div>
        </motion.div>
      )}
      <Container className="venue-banner-container">
        <motion.h4
          className="title"
          variants={contentVariant}
          initial="initial"
          animate="animate"
        >
          venues
        </motion.h4>
        <div className="venue-banner-section">
          <motion.div
            className="left-section"
            variants={contentVariant}
            initial="initial"
            animate="animate"
          >
            <h1 className="heading">{name}</h1>
          </motion.div>
          <motion.div
            className="right-section"
            variants={contentVariant}
            initial="initial"
            animate={{
              ...contentVariant.animate,
              transition: {
                ...contentVariant.animate.transition,
                delay: 0.25,
              },
            }}
          >
            <div className="description">
              {parseHTML(description?.data?.description)}
            </div>
            {!isMobile && (
              <div className="cta-section">
                <Link to={enquireURL} className="button">
                  <span>Enquire</span>
                </Link>
                <a href={`tel:${phone}`} className="button button-black">
                  <i className="icon icon-phone"></i>
                  <span> {phone}</span>
                </a>
              </div>
            )}
          </motion.div>
        </div>
      </Container>
    </div>
  )
}

export default VenueBanner
